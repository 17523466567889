<template>
	<div style="position: relative;">
		<!-- 导航 兼容md，lg，xl -->
		<div class="Navigation hidden-sm-only hidden-xs-only">
			<el-affix :offset="0" @change="affixChange">
				<div :class="{'active2':affixType}">
					<div style="max-width: 1400px;margin: 0 auto;">
						<el-row>
							<el-col :xs="24" :sm="24" :md="3" :lg="3" :xl="3">
								<img @click="tabsIndex(-1,'/')" style="cursor: pointer" src="../assets/logopc.png"  alt="">
							</el-col>
							<el-col :xs="24" :sm="24" :md="21" :lg="21" :xl="21">
								<div class="flex right_elastic  ">
									<div class="tabs flex" ref="setRef">
										<div ref="name0" @click="tabsIndex(0,'/about')">关于我们</div>
										<div ref="name1" @click="tabsIndex(1,'/news')">联合动态</div>
										<div ref="name2" @click="tabsIndex(2,'/business')">主营业务</div>
										<div ref="name3" @click="tabsIndex(3,'/cooperation')">合作与生态</div>
<!--										<div ref="name4" @click="tabsIndex(4,'/investor')">投资者关系</div>-->
										<div ref="name5" @click="tabsIndex(4,'/Joinus')">加入我们</div>
										<span v-if="tabID>-1" class="Line"
											:style="{width:tabs_width[tabID]+'px',left:leftSum?leftSum+(45*tabID)+'px':leftSum+'px'}"></span>
									</div>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
			</el-affix>
		</div>

		<!-- 兼容xs,sm -->
		<div class="Navigation1 hidden-xl-only hidden-lg-only hidden-md-only">
			<div class="floor flex center start">
				<div class="logo flex center">
					<img src="../assets/logoyd.png" alt="">
				</div>
				<div @click="menu = !menu">
					<img    src="../../src/assets/menu.png" style="width: 35px;margin-right: 10px;" alt="">
				</div>
				<div class="menu" :style="{bottom:menu?'-281px':'50px'}" v-if="menu">
					<ul @click="menu=!menu">
            <li ref="name6" @click="tabsIndex(-1,'/')">首页</li>
            <li ref="name0" @click="tabsIndex(0,'/about')">关于我们</li>
            <li ref="name1" @click="tabsIndex(1,'/news')">联合动态</li>
            <li ref="name2" @click="tabsIndex(2,'/business')">主营业务</li>
            <li ref="name3" @click="tabsIndex(3,'/cooperation')">合作与生态</li>
<!--            <li ref="name4" @click="tabsIndex(4,'/investor')">投资者关系</li>-->
            <li ref="name5" @click="tabsIndex(4,'/Joinus')">加入我们</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {ref, onMounted, reactive, nextTick, watch} from "vue";
	import { useRouter } from 'vue-router';
  import { storeToRefs } from 'pinia';
	import apiService from '@/api/index'

	import {useStore}from'@/store/index'
	export default {
		name: 'Navigation',
		setup(){

			const router = useRouter();

			const leftSum = ref(0);
			const tabs_width = ref([]);
			const setRef = ref('');
			const affixType = ref(false);
			const menu = ref(false)
			const imgurl = window.Image_url

      const store = useStore();
      const tabID=storeToRefs(store).curIndex

      watch(
          () =>store.curIndex,
          (indexs, prevIndexs) => {
            if(indexs<0){
              return
            }
            if (indexs === 0) {
              leftSum.value = 0
              return
            };

            let leftSums = tabs_width.value.filter((item, i) => {
              return indexs > i
            });
            leftSum.value = leftSums.reduce((prev, curr, idx, arr) => (prev + curr));
            // console.log(leftSum.value)
          }
      )
			nextTick(()=>{
				for(let i =0; i<6;i++){
					tabs_width.value.push(setRef.value.children[i].offsetWidth)
          // console.log(setRef.value.children[i].offsetWidth)
				};
			});

			const tabsIndex = (indexs,url)=>{
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: 'instant'
				})
        store.curIndex=indexs
				if(!url){
					return
				}

        router.push(url)
				// if(tabID === indexs && url){
				// 	return
				// };


			}


			const affixChange =  (e)=> {
				affixType.value = e
			};


			return {
				imgurl,
				affixType,
				menu,
				affixChange,
				tabsIndex,
				tabID,
				leftSum,
				tabs_width,
				setRef
			}
		}

	}
</script>

<style scoped lang="scss">
	ul,li{
		list-style: none;
		padding: 0;
		margin: 0;
	}
	.Navigation1 {
		.floor {
			.logo{
				span{
					margin-left: 10px;
				}
				img{
					width: 100px;border-radius: 5px;
					margin-left: 10px;
				}
			}
			.menu{
				li{
					padding: 10px 0 10px 50px;
					cursor: pointer;
				}
				position: absolute;
				left: 0;
				bottom: -281px;
				width: 100%;
				height: 280px;
				background-color: #ffffff;
				z-index: 101;
				overflow: hidden;
				transition: bottom 0.3s
			}
			border-radius: 0;
			background-image: none;
			background-color: white;
			border-bottom: 1px solid #75b1b14d;
			margin-bottom: 0;
			width: 100%;
			min-height: 40px;
			box-shadow: 0 0 8px 0 rgb(0 0 0 / 30%);
			position: relative;
		}

		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		margin: auto;
		width: 100%;
		z-index: 99;

	}

	.code {
		width: 148px;
		height: 158px;
		border-radius: 4px;
		background-color: #ffffff;
		position: fixed;
		right: 0;
		top: 350px;
		z-index: 99;
	}

	.active2 {
		background-color: rgb(51, 51, 51, 0.5);
		padding-top: 5px;

	}

	.Navigation {
		.tabs {
			.Line {
				position: absolute;
				left: 0;
				bottom: -3px;
				width: 100px;
				height: 4px;
				background-color: #F13B2D;
				border-radius: 4px;
				transition: left 0.3s // margin: 0 22px;
					// margin-left: 22px;
			}

			>div {
				// padding: 0 22px;
				padding-bottom: 5px;
				cursor: pointer;
				margin-right: 45px;
			}

			position: relative;
			color: #ffffff;
			font-size: 17px;
      top: 10px;
		}

		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		margin: auto;
		z-index: 99;
		width: 100%;
		padding-top: 25px;
    padding-bottom: 10px;
    background: rgba(128, 128, 128,0.5);
    //background: red;

	}

	.indicator {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 52px;
		margin: auto;
		color: #ffffff;
	}

	.indicator>div {
		width: 6px;
		height: 6px;
		border-radius: 50%;
		background-color: #ffffff;
		margin: 0 4px;
	}

	.indicator .active {
		height: 6px;
		border-radius: 10px;
		background-color: #ffffff;
		margin: 0 4px;
		width: 30px;
		transition: width 0.3s
	}

	.bjimg {
		width: 100%;
		height: 100%;

		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;

	}
</style>
