<template>
  <img
    style="width: 100%; height: 100%; margin-bottom: 47px"
    src="../../public/img/hzst.png"
    fit="fill"
  />
  <div v-if="list.length" style="position: relative">
    <!--		<el-carousel height="600px" indicator-position="none" @change="lbuChange" :autoplay="false">-->
    <!--			<el-carousel-item v-for="(item,index) in list"  :key="index">-->
    <!--				<div class="bjimg flex center" :style="{backgroundImage: 'url('+imgurl+item.img+')'}">-->
    <!--					<div class="flex_equally">-->
    <!--						<div class="title">-->
    <!--							{{item.title}}-->
    <!--							<i></i>-->
    <!--						</div>-->
    <!--						<div class="describe">{{item.desc}}</div>-->
    <!--						<div class="flex center">-->
    <!--							<div class="seat">联合创展</div>-->
    <!--						</div>-->
    <!--					</div>-->
    <!--				</div>-->
    <!--			</el-carousel-item>-->
    <!--		</el-carousel>-->
    <!--		<div class="indicator flex center">-->
    <!--			<div v-for="item in list.length" :key="item" :class="{'active':indexID === item-1}"></div>-->
    <!--		</div>-->
  </div>
  <div v-if="page_data.length">
    <!--    <div style="background-color: #ffffff;">-->
    <!--      <div class="subject-width plate1">-->
    <!--        <div class="title">{{ page_data[1].title }}</div>-->
    <!--        <div class="describe">{{ page_data[1].description }}</div>-->
    <!--        <div class="item">-->
    <!--          <img :src="imgurl+page_data[1].content[0].img" alt="" style="height: 405px;width: 100%;">-->
    <!--          <div class="txt">-->
    <!--            <el-row :gutter="15">-->
    <!--              <el-col :xs="24" :sm="16" :md="14" :lg="12" :xl="10">-->
    <!--                <div class="title">{{ page_data[1].content[0].title }}</div>-->
    <!--                <div class="describe">{{ page_data[1].content[0].desc }}</div>-->
    <!--              </el-col>-->
    <!--            </el-row>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="subject-width plate2">
      <div class="title">联合创展拥有的资质</div>
      <!--      <div>1</div>-->

      <el-row :gutter="15" justify="space-around " style="margin-top: 16px">
        <el-col :xs="24" :sm="24" :md="8" :lg="7" :xl="6">
          <div class="plate-img">
            <img
              src="../assets/qualification1.png"
              alt=""
              style="width: 230px; height: 330px"
            />
          </div>
          <div class="titles" style="text-align: center">
            建筑业企业资质证书
          </div>
        </el-col>

        <el-col :xs="24" :sm="24" :md="8" :lg="7" :xl="6">
          <div class="plate-img">
            <img
              src="../assets/qualification3.png"
              alt=""
              style="width: 230px; height: 330px"
            />
          </div>
          <div class="titles" style="text-align: center">工程设计资质证书</div>
        </el-col>
      </el-row>
    </div>

    <div class="case-container">
      <div class="case-title">工程案例</div>
      <div class="case-item">
        <div class="case-name">丽水职业技术学院艺术楼（提升工程）</div>
        <div class="red-line"></div>

        <div class="subject-width case-content1">
          <el-row style="width: 100%">
            <el-row style="width: 100%" :gutter="20">
              <el-col :span="8" class="img-container" style="height: 400px">
                <img style="width: 100%" src="../assets/caseImg/7-1.png" />
              </el-col>
              <el-col :span="8" class="img-container" style="height: 400px">
                <img style="width: 100%" src="../assets/caseImg/7-2.png" />
              </el-col>
              <el-col :span="8" class="img-container" style="height: 400px">
                <img style="width: 100%" src="../assets/caseImg/7-3.png" />
              </el-col>
            </el-row>

            <el-row class="margin-top-norm" style="width: 100%" :gutter="20">
              <el-col :span="12" class="img-container" style="height: 400px">
                <img style="width: 100%" src="../assets/caseImg/7-4.png" />
              </el-col>
              <el-col :span="12" class="img-container" style="height: 400px">
                <img style="width: 100%" src="../assets/caseImg/7-5.png" />
              </el-col>
            </el-row>
          </el-row>

          <!--          <div>-->
          <!--            <div>-->
          <!--              <img src="" alt="">-->
          <!--              <img src="" alt="">-->
          <!--            </div>-->
          <!--            <div>-->
          <!--              <img src="" alt="">-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div>-->
          <!--            <img src="" alt="">-->
          <!--          </div>-->

          <!--        </div>-->
        </div>
      </div>
      <div class="case-item">
        <div class="case-name">中铁办公楼装修工程</div>
        <div class="red-line"></div>

        <div class="subject-width case-content1">
          <el-row style="width: 100%" :gutter="20">
            <el-col :span="15">
              <el-row style="width: 100%">
                <el-col
                  class="img-container"
                  style="height: 260px; padding-right: 10px"
                  :span="12"
                >
                  <img style="width: 100%" src="../assets/caseImg/8-1.png" />
                </el-col>

                <el-col
                  class="img-container"
                  style="height: 260px; padding-left: 10px"
                  :span="12"
                >
                  <img style="width: 100%" src="../assets/caseImg/8-2.png" />
                </el-col>
              </el-row>

              <el-row class="margin-top-norm" style="width: 100%">
                <el-col class="img-container" style="height: 260px" :span="24">
                  <img style="width: 100%" src="../assets/caseImg/8-4.png" />
                </el-col>
              </el-row>

              <!--              <div >-->
              <!--                <img  style="width: 100%;height: 100px" src="../assets/caseImg/1.png" >-->
              <!--              </div>-->
            </el-col>
            <el-col :span="9" class="img-container" style="height: 540px">
              <img style="width: 100%" src="../assets/caseImg/8-3.png" />
            </el-col>
          </el-row>
          <!--          <div>-->
          <!--            <div>-->
          <!--              <img src="" alt="">-->
          <!--              <img src="" alt="">-->
          <!--            </div>-->
          <!--            <div>-->
          <!--              <img src="" alt="">-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div>-->
          <!--            <img src="" alt="">-->
          <!--          </div>-->

          <!--        </div>-->
        </div>
      </div>
      <div class="case-item">
        <div class="case-name">
          浙江旺荣半导体有限公司年产24万片8英寸功率器件
        </div>
        <div class="case-name">半导体项目一研发工程中心装饰工程</div>
        <div class="red-line"></div>

        <div class="subject-width case-content1">
          <el-row style="width: 100%">
            <el-row style="width: 100%" :gutter="20">
              <el-col :span="8" class="img-container" style="height: 380px">
                <img style="width: 100%" src="../assets/caseImg/4-1.png" />
              </el-col>
              <el-col :span="8" class="img-container" style="height: 380px">
                <img style="width: 100%" src="../assets/caseImg/4-2.png" />
              </el-col>
              <el-col :span="8" class="img-container" style="height: 380px">
                <img style="width: 100%" src="../assets/caseImg/4-3.png" />
              </el-col>
            </el-row>

            <el-row class="margin-top-norm" style="width: 100%" :gutter="20">
              <el-col :span="8" class="img-container" style="height: 380px">
                <img style="width: 100%" src="../assets/caseImg/4-4.png" />
              </el-col>
              <el-col :span="8" class="img-container" style="height: 380px">
                <img style="width: 100%" src="../assets/caseImg/4-5.png" />
              </el-col>
              <el-col :span="8" class="img-container" style="height: 380px">
                <img style="width: 100%" src="../assets/caseImg/4-6.png" />
              </el-col>
            </el-row>
          </el-row>

          <!--          <div>-->
          <!--            <div>-->
          <!--              <img src="" alt="">-->
          <!--              <img src="" alt="">-->
          <!--            </div>-->
          <!--            <div>-->
          <!--              <img src="" alt="">-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div>-->
          <!--            <img src="" alt="">-->
          <!--          </div>-->

          <!--        </div>-->
        </div>
      </div>

      <!--    -->
      <!--		<div style="background-color: #ffffff;">-->
      <!--			<div class="subject-width plate3">-->
      <!--				<div class="title">{{page_data[2].title}}</div>-->
      <!--				<div class="describe">{{page_data[2].description}}</div>-->
      <!--				<el-row :gutter="15" justify="space-between">-->
      <!--					<el-col :xs="24" :sm="24" :md="15" :lg="17" :xl="17">-->
      <!--						<div class="left">-->
      <!--							<el-carousel height="273px"   :autoplay="false">-->
      <!--								<el-carousel-item v-for="(item,index) in page_data[2].content"  :key="index">-->
      <!--									<el-image style="width: 100%;height: 100%;margin-bottom: 47px;" :src="imgurl+item.img" fit="none" />-->
      <!--								</el-carousel-item>-->
      <!--							</el-carousel>-->

      <!--							<div class="title" style="text-align: left;">联合创展介绍</div>-->
      <!--							<div class="describe" style="text-align: left;line-height: 37px;">联合创展，致力于成为全国最大规模的互联网家装平台，联合创展，致力于成为全最大规模的互联网家装平台，成为全国最大规模的互联网家装平台，联合创展，致力于成为全最大规模的互联网家装平台，</div>-->
      <!--						</div>-->
      <!--					</el-col>-->
      <!--					<el-col :xs="24" :sm="24" :md="9" :lg="7" :xl="7">-->
      <!--						<div class="flex column start" style="height: 100%;">-->
      <!--							<div class="right" style="margin-bottom: 10px;">-->
      <!--								<div class="titlef">孵化加速</div>-->
      <!--								<div class="describex">联合创展，致力于成为全国最大规模成为规模的互联全国最大规模的互联网家装平台</div>-->
      <!--							</div>-->
      <!--							<div class="right" style="margin-bottom: 10px;">-->
      <!--								<div class="titlef">大企业创新</div>-->
      <!--								<div class="describex">联合创展，致力于成为全国最大规模成为规模的互联全国最大规模的互联网家装平台</div>-->
      <!--							</div>-->
      <!--							<div class="right">-->
      <!--								<div class="titlef">创业投资</div>-->
      <!--								<div class="describex">联合创展，致力于成为全国最大规模成为规模的互联全国最大规模的互联网家装平台</div>-->
      <!--							</div>-->
      <!--						</div>-->
      <!--					</el-col>-->
      <!--				</el-row>-->
      <!--			</div>-->
      <!--		</div>-->
      <!--		<div class="subject-width plate4">-->
      <!--			<div class="title">{{page_data[3].title}}</div>-->
      <!--			<div class="describe">{{page_data[3].description}}</div>-->
      <!--			<el-row :gutter="15" justify="space-between">-->
      <!--				<el-col :xs="24" :sm="24" :md="15" :lg="12" :xl="12">-->
      <!--					<div class="left">-->
      <!--						<el-carousel height="419px"   :autoplay="false">-->
      <!--							<el-carousel-item v-for="(item,index) in page_data[3].content"  :key="index">-->
      <!--								<el-image style="width: 100%;height: 100%;margin-bottom: 47px;" :src="imgurl+item.img" fit="none" />-->
      <!--							</el-carousel-item>-->
      <!--						</el-carousel>-->

      <!--					</div>-->
      <!--				</el-col>-->
      <!--				<el-col :xs="24" :sm="24" :md="9" :lg="12" :xl="12">-->
      <!--					<div class="right" >-->
      <!--						<div class="titlef">云+生态</div>-->
      <!--						&lt;!&ndash; <div class="describex">联合创展，致力于成为全国最大规模成为规模的互联全国最大规模的互联网家装平台</div> &ndash;&gt;-->
      <!--					</div>-->
      <!--					<div class="right">-->
      <!--						<div class="titlef">免费对象</div>-->
      <!--						<div class="describex">联合创展，致力于成为全国最大规模的互联网家装平台，联合创展，致力于成为全国最大规模的互联网家装平台</div>-->
      <!--					</div>-->
      <!--					<div class="right">-->
      <!--						<div class="titlef">扶持内容</div>-->
      <!--						<div class="describex">联合创展，致力于成为全国最大规模的互联网家装平台，联合创展，致力于成为全国最大规模的互联网家装平台</div>-->
      <!--					</div>-->
      <!--				</el-col>-->
      <!--			</el-row>-->
      <!--		</div>-->
    </div>
  </div>
</template>

<script setup>
import apiService from '@/api/index';
import { ref, onMounted, reactive } from "vue";

const list = ref([]);
const indexID = ref(0);
const imgurl = window.Image_url
const page_data = ref([])
apiService.api_partner().then((res) => {
  console.log(res)
  page_data.value = res.data
  list.value = res.data[0].content
});
const lbuChange = (e) => {
  indexID.value = e
};

</script>

<style scoped lang="scss">
.case-container {
  .margin-top-norm {
    margin-top: 20px;
  }

  .img-container {
    width: 100%;
    overflow: hidden;
  }

  background: white;

  .case-title {
    margin-bottom: 10px;
    padding: 30px 0 10px 0;
    text-align: center;
    font-size: 32px;
    color: #000;
    font-weight: 500;
    width: 100vw;
  }

  .case-item {
    margin-bottom: 80px;

    .case-name {
      width: 100vw;
      text-align: center;
      color: #666666;
      font-size: 28px;
    }

    .red-line {
      width: 80px;
      background: #c82126;
      height: 4px;
      margin: 20px auto;
    }

    .case-content1 {
      margin-top: 10px;
      //width: 100%;
      display: flex;
    }
  }
}

.plate4 {
  .right {
    .describex {
      color: #333333;
      line-height: 37px;
      font-size: 20px;
    }

    .titlef {
      color: #333333;
      font-size: 28px;
    }

    margin-bottom: 20px;
  }

  .describe {
    font-size: 24px;
    font-family: AlibabaPuHuiTi_2_65_Medium;
    color: #555555;
    line-height: 37px;
    text-align: center;
    padding-top: 16px;
    margin-bottom: 40px;
  }

  .title {
    font-size: 34px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 48px;
    text-align: center;
  }

  padding: 45px 90px;
}

.plate3 {
  .right {
    .describex {
      font-size: 20px;
      font-family: AlibabaPuHuiTi_2_65_Medium;
      color: #555555;
      line-height: 37px;
    }

    .titlef {
      color: #333333;
      line-height: 40px;
      font-size: 28px;
    }

    background: #fcfbfb;
    box-shadow: 0px 2px 20px 5px rgba(215, 187, 185, 0.19);
    border: 2px solid #ffffff;
    padding: 10px 12px;
  }

  .left {
    padding: 34px 43px;
    background: #fcfbfb;
    box-shadow: 0px 2px 20px 5px rgba(215, 187, 185, 0.19);
    border: 2px solid #ffffff;
  }

  .describe {
    color: #555555;
    font-size: 24px;
    padding-top: 14px;
    text-align: center;
    margin-bottom: 54px;
  }

  .title {
    color: #2a2a2a;
    font-size: 34px;
    text-align: center;
    font-weight: 600;
  }

  padding: 94px 0;
}

.plate1 {
  .item {
    .txt {
      .describe {
        color: #555555;
        font-size: 24px;
        padding-top: 14px;
        text-align: left;
      }

      .title {
        color: #333333;
        font-size: 34px;
        text-align: left;
      }

      position: absolute;
      left: 30px;
      bottom: 0px;
      text-align: left;
      padding-bottom: 40px;
    }

    text-align: center;
    position: relative;
  }

  .describe {
    color: #555555;
    font-size: 24px;
    padding-top: 14px;
    text-align: center;
  }

  .title {
    color: #2a2a2a;
    font-size: 34px;
    text-align: center;
    font-weight: 600;
  }

  padding: 54px 0;
}

.plate2 {
  .plate-img {
    text-align: center;
  }

  .describes {
    font-size: 24px;
    font-family: AlibabaPuHuiTi_2_65_Medium;
    color: #555555;
    line-height: 37px;
  }

  .titles {
    color: #333333;
    font-size: 34px;
    margin-bottom: 16px;
    padding-top: 34px;
  }

  .describe {
    color: #555555;
    font-size: 24px;
    text-align: center;
    padding-top: 16px;
    padding-bottom: 98px;
  }

  .title {
    color: #333333;
    font-size: 34px;
    text-align: center;
    font-weight: 600;
  }

  padding: 45px 0;
}

.bjimg {
  .seat {
    width: 134px;
    height: 42px;
    border-radius: 10px;
    border: 1px solid #ffffff;
    color: #ffffff;
    line-height: 42px;
    text-align: center;
    margin-top: 40px;
  }

  .describe {
    color: #ffffff;
    font-size: 20px;
    text-align: center;
    padding-top: 30px;
  }

  .title {
    i {
      position: absolute;
      bottom: -10px;
      left: 0;
      right: 0;
      margin: auto;
      width: 74px;
      border: 1px solid #ffffff;
    }

    margin-bottom: 10px;
    text-align: center;
    color: #ffffff;
    font-size: 36px;
    position: relative;
  }

  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.indicator {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 52px;
  margin: auto;
  color: #ffffff;
}

.indicator > div {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #ffffff;
  margin: 0 4px;
}

.indicator .active {
  height: 6px;
  border-radius: 10px;
  background-color: #ffffff;
  margin: 0 4px;
  width: 30px;
  transition: width 0.3s;
}
</style>
